import { createI18n } from 'vue-i18n';
import ru from '../config/i18n/ru';
import ua from '../config/i18n/ua';
import en from '../config/i18n/en';

export default createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'ru',
  messages: {
    en,
    ru,
    ua,
  },
  datetimeFormats: {},
});
