import { LocaleMessages } from 'vue-i18n';

export default <LocaleMessages<any>>{
  $vuetify: {
    home: {
      section_hero: {
        heading: 'Enjoy Yourself',
      },
      section_about: {
        heading: 'About Us',
        description: 'Description in English',
        first: 'First block',
        second: 'Second block',
        third: 'Third block',
        fourth: 'Fourth block',
        fifth: 'Fifth block',
        sixth: 'Sixth block',
      },
      section_services: {
        heading: 'Services',
      },
      section_portfolio: {
        heading: 'Portfolio',
        description: 'Portfolio description',
      },
      section_contact: {
        heading: 'Contact',
        description: 'Contact description',
      },
    },
    navigation: {
      home: 'Home',
      services: 'Services',
      contacts: 'Contacts',
      team: 'Our Team',
      about_us: 'About us',
    },
    actions: {
      book: 'Book now',
      send: 'Send',
      select_date: 'Select',
      select_date_now: 'Now',
    },
    forms: {
      phone: 'Phone number',
      full_name: 'Full Name',
      procedures: 'Procedures',
      date: 'Date',
      service: 'Service',
      price: 'price',
    },
    route_meta_titles: {
      service: 'Service',
    },
  },
};
