class LocalStorageUtil {
  get(key: string): string | null {
    return localStorage.getItem(key) || null;
  }

  set(key: string, item: any, overwrite = true): boolean {
    if (overwrite) {
      localStorage.setItem(key, item);
      return true;
    } else if (!overwrite && !localStorage.getItem(key)) {
      localStorage.setItem(key, item);
      return true;
    }

    return false;
  }

  remove(key: string): boolean {
    if (this.get(key)) localStorage.removeItem(key);

    return true;
  }
}

export default new LocalStorageUtil();

export enum LocalStorage {
  Locale = 'locale',
}
