import { DisplayBreakpoint } from 'vuetify';

type DisplayThresholds = {
  [key in DisplayBreakpoint]: number;
};

export const BreakpointNamesEnum = <Record<string, DisplayBreakpoint>>{
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

export const BreakpointsEnum = <DisplayThresholds>{
  [BreakpointNamesEnum.xs]: 0,
  [BreakpointNamesEnum.sm]: 500,
  [BreakpointNamesEnum.md]: 768,
  [BreakpointNamesEnum.lg]: 1200,
  [BreakpointNamesEnum.xl]: 1920,
};
