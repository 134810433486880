/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

//TODO: Uncomment when overriding of SCSS variables is done
//Overwrite native Variables from Vuetify
import '@/styles/settings.scss';

import { createVuetify, ThemeDefinition } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import { fa } from 'vuetify/iconsets/fa';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import {
  BreakpointNamesEnum,
  BreakpointsEnum,
} from '@/interface/enums/BreakpointsEnum';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { useI18n } from 'vue-i18n';
import i18n from '@/plugins/i18n';
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides

//TODO: Remove shade and tint colors and replace them in the code with light and dark
const mainColors = {
  primary: '#f6eee2',
  'primary-contrast': '#000000',
  'primary-shade': '#d8d1c7',
  'primary-dark': '#d8d1c7',
  'primary-tint': '#f7f0e5',
  'primary-light': '#f7f0e5',
  secondary: '#971B1B',
  'secondary-contrast': '#ffffff',
  'secondary-shade': '#851818',
  'secondary-dark': '#851818',
  'secondary-tint': '#a13232',
  'secondary-light': '#a13232',
};

const informationalColors = {
  success: '#2dd36f',
  warning: '#ffc409',
  danger: '#eb445a',
};

const miscColors = {
  gold: '#AFA293',
  white: '#ffffff',
  black: '#000000',
  background: '#f1e2c9',
};

const alphaColors = {
  // 'white-25': 'rgba(255,255,255,0.5)',
  'white-50': 'rgba(255,255,255, 0.5)',
  'white-75': 'rgba(255,255,255, 0.75)',
};

const appTheme: ThemeDefinition = {
  dark: false,
  colors: {
    ...mainColors,
    ...miscColors,
    ...informationalColors,
    ...alphaColors,
  },
};

export default createVuetify({
  components,
  directives,
  defaults: {},
  display: {
    mobileBreakpoint: BreakpointNamesEnum.sm,
    thresholds: {
      [BreakpointNamesEnum.sm]: BreakpointsEnum[BreakpointNamesEnum.sm],
      [BreakpointNamesEnum.xs]: BreakpointsEnum[BreakpointNamesEnum.xs],
      [BreakpointNamesEnum.sm]: BreakpointsEnum[BreakpointNamesEnum.sm],
      [BreakpointNamesEnum.md]: BreakpointsEnum[BreakpointNamesEnum.md],
      [BreakpointNamesEnum.lg]: BreakpointsEnum[BreakpointNamesEnum.lg],
      [BreakpointNamesEnum.xl]: BreakpointsEnum[BreakpointNamesEnum.xl],
    },
  },
  theme: {
    defaultTheme: 'appTheme',
    themes: {
      appTheme,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      fa,
      mdi,
    },
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n: i18n, useI18n }),
  },
});
