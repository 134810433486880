import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import * as routeNames from '@/constants/routeNames';

import { useAccountStore } from '@/store/account';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from '@/firebase';

const routes: RouteRecordRaw[] = [
  {
    name: routeNames.DEFAULT_LAYOUT,
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        name: 'AboutMe',
        path: 'about',
        component: () => import('@/views/AboutMe.vue'),
      },
      {
        name: routeNames.HOME_VIEW,
        path: '',
        component: () => import('@/views/HomeView.vue'),
        props: {},
        meta: {
          title: 'Main',
        },
      },
    ],
  },
  {
    name: 'AdminLayout',
    path: '/admin',
    meta: {
      admin: true,
      requiresAuth: true,
    },
    component: () => import('@/admin/layouts/AdminLayout.vue'),
    children: [
      {
        name: 'AdminDashboard',
        path: '',
        component: () => import('@/admin/views/AdminDashboard.vue'),
        children: [],
      },
      {
        name: 'Appointments',
        path: '/appointments',
        component: () => import('@/admin/views/AppointmentsListing.vue'),
      },
      {
        name: 'ContactMeListing',
        path: '/contact-me',
        component: () => import('@/admin/views/ContactMeListing.vue'),
      },
      {
        name: 'ServicesListing',
        path: '/services',
        component: () => import('@/admin/views/ServicesListing.vue'),
      },
    ],
  },
  {
    name: 'AdminAuthView',
    component: () => import('@/admin/views/AdminAuthView.vue'),
    path: '/admin/auth',
    alias: '/login',
  },
  {
    name: routeNames.NOT_FOUND_VIEW,
    path: '/:notFoundParams(.*)*',
    component: () => import('@/views/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const account = useAccountStore();

  const isAuthenticated = account.isAuthenticated;

  const isProtectedRoute = to.matched.some(
    (record) => record.meta.requiresAuth,
  );
  const isAuthRoute = to.matched.some(
    (record) => record.name === 'AdminAuthView',
  );

  if (isProtectedRoute) {
    if (!isAuthenticated) {
      next({ name: routeNames.ADMIN_AUTH_VIEW });
    } else {
      next();
    }
  } else if (isAuthRoute) {
    if (isAuthenticated) next({ name: routeNames.ADMIN_HOME_VIEW });
    else next();
  } else {
    next();
  }
});

export default router;
