import { getAuth, signOut, signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseApp } from '@/firebase';
import client from '@/config/axios';

const auth = getAuth(firebaseApp);

export const loginAdmin = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const logoutAdmin = () => {
  return signOut(auth);
};

export const getAdminData = () => {
  return client.get('/api/admin');
};

export const getUser = (userId: string) => {
  return client.get(`/api/user/${userId}`);
};
