import { LocaleMessages } from 'vue-i18n';

export default <LocaleMessages<any>>{
  $vuetify: {
    home: {
      section_hero: {
        heading: 'Люби Себя',
      },
      section_about: {
        heading: 'Про нас',
        description: 'Про нас на русском',
        first: 'First block',
        second: 'Second block',
        third: 'Third block',
        fourth: 'Fourth block',
        fifth: 'Fifth block',
        sixth: 'Sixth block',
      },
      section_services: {
        heading: 'Услуги',
      },
      section_portfolio: {
        heading: 'Портфолио',
        description: 'Описание портфолио',
      },
      section_contact: {
        heading: 'Обратная связь',
        description: 'Contact description',
      },
    },
    navigation: {
      home: 'Главная',
      services: 'Услуги',
      contacts: 'Контакты',
      team: 'Наша команда',
      about_us: 'О нас',
    },
    actions: {
      book: 'Записаться',
      send: 'Отправить',
      select_date: 'Выбрать',
      select_date_now: 'Сейчас',
    },
    forms: {
      phone: 'Номер телефона',
      full_name: 'Имя Фамилия',
      procedures: 'Все услуги',
      date: 'Дата',
      service: 'Процедура',
    },
    route_meta_titles: {
      service: 'Услуга',
    },
  },
};
