import { defineStore } from 'pinia';
import LocalStorageUtil from '@/helpers/localStorageUtil';
import { loginAdmin } from '@/admin/firebase/admin';

export const useAccountStore = defineStore('account', {
  state: (): any => ({
    user: null,
    isAuthenticated: false,
  }),
  actions: {
    async authenticate(email: string, password: string) {
      try {
        const credentials = await loginAdmin(email, password);

        const token = await credentials.user.getIdToken();

        LocalStorageUtil.set('authToken', token);
        this.isAuthenticated = true;
      } catch (e) {
        console.error('Error logging in');
        throw e;
      }
    },
    async logout() {
      this.isAuthenticated = false;
      LocalStorageUtil.remove('authToken');
    },
  },
  getters: {
    isAdmin(state) {
      return !!state.user;
    },
    admin(state) {
      return state.user;
    },
  },
});
