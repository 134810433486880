import axios, {
  InternalAxiosRequestConfig,
  AxiosError,
  AxiosInstance,
  AxiosResponse,
} from 'axios';
import { logoutAdmin } from '@/admin/firebase/admin';
import { createService } from '@/firebase/service/services';
import LocalStorageUtil from '@/helpers/localStorageUtil';
import { HttpStatus } from '@/interface/enums/HttpStatus';
import { useRoute, useRouter } from 'vue-router';
import { HOME_VIEW } from '@/constants/routeNames';

const client: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 1000,
});

client.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = LocalStorageUtil.get('authToken');

    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    if (
      error.response &&
      error.response.status === HttpStatus.Unauthentiacted
    ) {
      await logoutAdmin();

      const router = useRouter();

      await router.push({ name: HOME_VIEW });
    }
    return Promise.reject(error);
  },
);

export default client;
